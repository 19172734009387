import React, { useState } from 'react';
import axios from 'axios';  // Correct axios import

const App = () => {
  const [data, setData] = useState(null);  // State to hold fetched data

  // Function to handle the button click and fetch data
  async function handleClick() {
    try {
      const response = await axios.get('http://justinch.tech:8080/');  // Ensure HTTP, not HTTPS
      setData(response.data);  // Set the fetched data to state
      console.log(response.data);  // Log the response for debugging
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <div>
      <h1>My First Application</h1>
      <button onClick={handleClick}>Click me</button>
      {data ? (
        <div>
          <p>Name: {data.name}</p>
          <p>Last Name: {data.lname}</p>
        </div>
      ) : (
        <p>Click the button to load data</p>  // Show a message instead of "Loading..." initially
      )}
    </div>
  );
}

export default App;

